.test {
    width: 300px;
    height: 300px;
    box-shadow: 10px 10px 8px #120651;

    border-radius: 15px; 
}
.dices {
    height: 300px;
    display: flex;
    gap: 100px;
}
.diceAnimation {
    width: 300px;
    height: 300px;
    box-shadow: 10px 10px 8px #120651;

    border-radius: 15px;
    animation: rolldice 5s;
}
@keyframes rolldice {
    from {
        transform: none;
    }
    to {
        transform: rotate(360deg);
    }
}
.button {
    padding: 1rem 5rem;
    border: none;
    background-color: #FFDEF3;
    color:'#35212A';
    border-radius: 1rem;
}
.dicePage {
    margin: 1.7rem 0rem;
    min-height: 580px;
}

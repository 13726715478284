* {
    font-family: 'Comfortaa', cursive;
}
header {
    height: 6rem;
    background-color: #120651;
    color:white;
}
body {
    background-color: #ffffff;
    
}
h1 {
    font-weight: 600;
    font-size:1.75rem;
}
h2 {
    font-weight: 500;
    color:rgb(42, 42, 42);
    font-size:1.4rem;
}
h1,h2,h3,h4,p {
    margin:0;
    padding:0;
}
a{
    color:white;
    text-decoration: none;
}
a:hover {
    color:white;
    transition:.3s;
}
nav {
    height: 6rem;
}
ul {
    margin: 0rem;
    padding: 0rem;
}
p {
    margin: 0rem;
}
li {
    list-style-type: none;
}
.home-cards{
    
}
.card2 {
    width: 15rem;
    height: 20rem;
    border: 2px solid black;
    padding: 1rem;
}
footer {
    background-color: #120651;
    color: white;
   width: 100%;
}
.card {
    height: 400px;
    
    top: 120px;
    left: 500px;
    width: 250px;
    background-color: #120651;
}
/* .blogCard {
    background-color: antiquewhite;
} */
.bcw{
    background-color: white !important;
    /* box-shadow: 0 0.5rem 1rem #FFDEF3;
    border-color: #120651;  */
}
.bce{
    border: 1px solid #120651;
    border-radius: 0.25rem;
}
.animation {
    height: 400px;
    
    top: 120px;
    left: 500px;
    width: 250px;
    background-color: #FFDEF3;
    animation: cardAnimation 1.4s;
}
/* .animationH {
    height: 93px;
    width: 248px;
} */
.dropdown-menu {
    min-width: 2rem;
}
.btn-secondary {
    background-color: #120651;
    border-color: #120651;
}
.btn-secondary:hover {
    background-color: #120651;
    border-color: #120651;
}
.btn:focus {
    box-shadow: none;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
    color: #fff;
    background-color: #120651;
    border-color: #120651;
    box-shadow: none;
}
.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #120651;
    border-color: #120651;
}
.cardParent {
    position: relative;
    height: 640px;
    

}
.menuButton {
    display: none;
}
.sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: rgb(255, 255, 255);
    top: 0;
    width: 20rem;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.651);
    min-height: 100%;
    height: auto;
    padding: 2rem;
    right: 0;
    z-index: 100;
    font-size: 1.2rem;
}
.headerLinks {
    display: flex;
}
.copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    background-color: #FCF9D8;
    color: black !important;
}
.diceButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.w-100{
    width:100%;
}
.w-60{
    width:60%;
}
.landing-card{
    display: flex;
    align-items: center;
    gap: 4rem;
    font-family: 'Poppins', sans-serif !important;
    margin-top: 1rem;
    width: 100%;
}
.fl-row{
    flex-direction: row-reverse;
}
.landing-img{
    height: 600px;
}
@keyframes cardAnimation {
    from {
        top: 100px;
    }
    to {
        top: 20px;
    }
}
@media only screen and (max-width: 900px) {
    .cardParent {
        margin-right: 4rem;
    }
    .landing-img{
        height: 500px;
    }
}
@media only screen and (max-width: 760px) {
    .cardParent {
        margin-right: 7rem;
    }
    .menuButton {
        display: flex;
    }
    .headerLinks {
        display: none;
    }
    .landing-card{
        flex-direction: column;
    }
    .fl-row{
        flex-direction: column;
    }
    .landing-img{
        height: 400px;
    }
}
@media only screen and (max-width: 600px) {
    .cardParent {
        margin-right: 10rem;
    }
    .cardTitle {
        padding-bottom:3rem !important;
    }
    .diceButtons {
        flex-direction: column;
    }
    .headerLinks {
        display: none;
    }
}
@media only screen and (max-width: 450px) {
    .cardParent {
        margin-right: 12rem;
    }
}
